// Translated from num2words python library
// https://github.com/savoirfairelinux/num2words/blob/master/num2words/lang_ID.py
// Accessed: 10 Dec 2024

import { Injectable } from '@angular/core';

interface WordBlock {
  number: string;
  spelling: string[];
}

@Injectable({
  providedIn: 'root',
})
export class NumberToWordsService {
  private BASE: { [key: string]: string[] } = {
    '0': [],
    '1': ['satu'],
    '2': ['dua'],
    '3': ['tiga'],
    '4': ['empat'],
    '5': ['lima'],
    '6': ['enam'],
    '7': ['tujuh'],
    '8': ['delapan'],
    '9': ['sembilan'],
  };

  private TENS_TO: { [key: number]: string } = {
    3: 'ribu',
    6: 'juta',
    9: 'miliar',
    12: 'triliun',
    15: 'kuadriliun',
    18: 'kuantiliun',
    21: 'sekstiliun',
    24: 'septiliun',
    27: 'oktiliun',
    30: 'noniliun',
    33: 'desiliun',
  };

  private MAX_VALUE = 10 ** 36;
  private ERROR_NUMBER_TOO_BIG =
    'Number is too large to convert to words (abs(%s) > %s).';

  private splitByComma(number: number): string[] {
    return number.toString().split('.');
  }

  private splitBy3(number: string): string[] {
    const length = number.length;

    if (length < 3) {
      return [number];
    }

    const numberBlocks: string[] = [];
    const lenOfFirstNumberBlock = length % 3;
    if (lenOfFirstNumberBlock > 0) {
      const firstNumberBlock = number.slice(0, lenOfFirstNumberBlock);
      numberBlocks.push(firstNumberBlock);
    }

    for (let i = lenOfFirstNumberBlock; i < length; i += 3) {
      const nextNumberBlock = number.slice(i, i + 3);
      numberBlocks.push(nextNumberBlock);
    }

    return numberBlocks;
  }

  private spell(numberBlocks: string[]): WordBlock[] {
    const wordBlocks: WordBlock[] = [];
    const firstNumberBlock = numberBlocks[0];
    let spelling: string[] = [];

    if (firstNumberBlock.length === 1) {
      if (firstNumberBlock === '0') {
        spelling = ['nol'];
      } else {
        spelling = this.BASE[firstNumberBlock];
      }
    } else if (firstNumberBlock.length === 2) {
      spelling = this.puluh(firstNumberBlock);
    } else {
      spelling = this.ratus(firstNumberBlock[0]).concat(
        this.puluh(firstNumberBlock.slice(1, 3)),
      );
    }

    let wordBlock: WordBlock = {
      number: firstNumberBlock,
      spelling,
    };

    wordBlocks.push(wordBlock);

    for (const numberBlock of numberBlocks.slice(1, numberBlocks.length)) {
      spelling = this.ratus(numberBlock[0]).concat(
        this.puluh(numberBlock.slice(1, 3)),
      );
      wordBlock = {
        number: numberBlock,
        spelling,
      };
      wordBlocks.push(wordBlock);
    }

    return wordBlocks;
  }

  private ratus(number: string): string[] {
    if (number === '1') {
      return ['seratus'];
    } else if (number === '0') {
      return [];
    } else {
      return this.BASE[number].concat(['ratus']);
    }
  }

  private puluh(number: string): string[] {
    if (number[0] === '1') {
      if (number[1] === '0') {
        return ['sepuluh'];
      } else if (number[1] === '1') {
        return ['sebelas'];
      } else {
        return this.BASE[number[1]].concat(['belas']);
      }
    }

    if (number[0] === '0') {
      return this.BASE[number[1]];
    }

    return this.BASE[number[0]].concat(['puluh'], this.BASE[number[1]]);
  }

  private spellFloat(floatPart: string): string {
    let wordList: string[] = [];

    for (const n of floatPart) {
      if (n === '0') {
        wordList = wordList.concat(['nol']);
        continue;
      }
      wordList = wordList.concat(this.BASE[n]);
    }

    return ` koma ${wordList.join(' ')}`;
  }

  private join(wordBlocks: WordBlock[], floatPart: string): string {
    let wordList: string[] = [];
    const length = wordBlocks.length - 1;
    const firstBlock = wordBlocks[0];
    let start = 0;

    if (length === 1 && firstBlock.number[0] === '1') {
      wordList = wordList.concat(['seribu']);
      start = 1;
    }

    for (let i = start; i < length + 1; i++) {
      wordList = wordList.concat(wordBlocks[i].spelling);
      if (wordBlocks[i].spelling.length === 0) {
        continue;
      }
      if (i === length) {
        break;
      }
      wordList = wordList.concat([this.TENS_TO[(length - i) * 3]]);
    }

    return wordList.join(' ') + floatPart;
  }

  toCardinal(number: number): string {
    if (number >= this.MAX_VALUE) {
      return this.ERROR_NUMBER_TOO_BIG;
    }
    let minus = '';
    if (number < 0) {
      minus = 'min ';
    }
    let floatWord = '';
    const n = this.splitByComma(Math.abs(number));
    if (n.length === 2) {
      floatWord = this.spellFloat(n[1]);
    }
    return minus + this.join(this.spell(this.splitBy3(n[0])), floatWord);
  }
}
